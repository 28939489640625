import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import "../styles/styles.scss"
import "tachyons"
import CardList from "../components/Cardlist"
import Logo from "../../static/localcoin-animated.svg"
import PacmanLoader from "react-spinners/PacmanLoader"

export default function Home() {
  //Initial state of our Array of shops and the input in search field
  const [shopArray, setShopArray] = useState([])
  const [filteredShops, setFilteredShops] = useState([])
  const [searchField, setSearchField] = useState("")

  const handleInputChange = event => {
    setSearchField(event.target.value)
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    const filtered = shopArray.filter(shop => {
      //console.log(searchField)
      return (
        shop.name.toLowerCase().includes(searchField.toLowerCase()) ||
        shop.location.city.toLowerCase().includes(searchField.toLowerCase())
      )
    })
    setFilteredShops(filtered)
  }
  //Fetching the shop json response from the API
  useEffect(() => {
    fetch(
      `https://cors-anywhere.herokuapp.com/https://public.localcoinatm.com/service/api/v2/location`,
      { XRequestedWith: "XMLHttpRequest" }
    )
      .then(response => response.json())
      .then(resultData => {
        // Changing the response object to array of objects and then filtering only localcoin
        const shops = Object.values(resultData)
        const localcoinShops = shops.filter(shop => {
          return shop.operatorName === "Localcoin"
        })

        //Sorting the shops in the hook
        localcoinShops.sort((a, b) => {
          var nameA = a.name.toUpperCase() // ignore upper and lowercase
          var nameB = b.name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          // names must be equal
          return 0
        })

        setShopArray(localcoinShops)
        setFilteredShops(localcoinShops)
        // console.log(filteredShops)
      })
      .catch(err => console.log("Couldn't fetch shops", err))
  }, [])

  //This section filtered the shops after every key press and rerendered but to match the conditions of the problem more carefully

  //React hook when searchField or the shopArray are changed new value for filtered shops
  // useEffect(() => {
  //   const filtered = shopArray.filter(shop => {
  //     //console.log(searchField)
  //     return (
  //       shop.name.toLowerCase().includes(searchField.toLowerCase()) ||
  //       shop.location.city.toLowerCase().includes(searchField.toLowerCase())
  //     )
  //   })
  //   // filtered.sort();
  //   setFilteredShops(filtered)
  // }, [searchField, shopArray])

  return (
    <div>
      {/* Header Tags for better SEO performance */}
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Localcoin</title>
        <meta name="description" content='A case study for localcoin' />
        <link rel="canonical" href="https://localcoin-superawesome.netlify.app" />
      </Helmet>
      {/* <AnimatedLogo /> */}
      <img className="pa3" src={Logo} alt="logo" width="150px" />
      <div className="intro-container tc mb5 pa3">
        <h2 className="mv3 f2 fw5 tracked">Sell Bitcoin</h2>
        <p className="mv2 tracked f4">
          You will be able to get your money in minutes!
        </p>
        <div className="input-bar center mw6">
          <input
            className="bn fl w-80"
            type="search"
            placeholder="Enter your address"
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <button
            type="submit"
            onClick={handleSubmit}
            className=" bn bg-orange white fl w-20 "
          >
            <svg
              className=""
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
            >
              <path
                d="M14.5 14.5l-4-4m-4 2a6 6 0 110-12 6 6 0 010 12z"
                stroke="currentColor"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {/* Checking for any shops loading from api if not then show a spinner */}
      {shopArray.length ? (
        <div className="cards-container">
          <CardList shopArray={filteredShops} />
        </div>
      ) : (
        <div className="w-10 center">
          <PacmanLoader color={"#ff6300"} />
        </div>
      )}
    </div>
  )
}
